import { get, post, del, put } from "./collect";
import { fcBaseURL } from "./request";

// 登录
export const login = (params) => {
  return post("/users/staff/admin/login", params);
};

// 退出登录
export const loginOut = () => {
  return post("/users/staff/admin/logout");
};

// 首次登录修改密码
export const newEditLogin = (params) => {
  return post("/users/staff/admin/init/change-passwd", params);
};

// 修改密码
export const editPassword = (id, params) => {
  return put(`/users/staff/admin/${id}/change-passwd`, params);
};

// 更新头像
export const upUserImg = (id, image) => {
  return put(`/users/staff/admin/${id}/change-image`, {
    image,
  });
};

/**
 * 常用设置列表
 * tx: 订单列表
 * */ 
export const getUsedTo = (userId, func) => {
  return get(`/users/staff/admin/${userId}/used-to?func=${func}`);
};

/**
 * 添加常用设置
 * tx: 订单列表
 * {
    "func": "tx",
    "isDefault": true,
    "name": "我的常用搜索2",
    "condition": {
        "status":"1",
        "storeProperty":"all",
        "export":"N",
        "dateFrom":"2024-01-01",
        "dateTo":"2024-10-31",
        "auditIds":"936,908"
    }
  }
 * */ 
export const postUsedTo = (userId, func, isDefault, name, condition) => {
  return post(`/users/staff/admin/${userId}/used-to`, {
    func,
    isDefault,
    name,
    condition
  });
};

/**
 * 删除常用设置
 * tx: 订单列表
 * {
    "func": "tx",
    "name": "我的常用搜索2"
  }
 * */ 
export const delUsedTo = (userId, func, name) => {
  return del(`/users/staff/admin/${userId}/used-to`, {
    func,
    name
  });
};

// 系统限制下单
export const systemRestrict = (type, date) => {
  let param = {}
  if (date) {
    // 限制下单时传参数 date, 解除时不需要参数
    param.date = date
  } else {
    param.date = null
  }
  return post(`/system/admin/restrict/${type}`, param);
};

// 首页订单月份设置
export const systemOrderDate = (date) => {
  let param = {
    date
  }
  return post(`/system/admin/order-date`, param);
};

// 单个查询用户信息
export const getUserInfo = (id) => {
  return get(`/users/staff/admin/${id}`);
};

// 获取验证码
export const getCode = (verifyId) => {
  return post("/sms/send", {
    verifyId,
    verifyType: "modify_phone",
  });
};

// 更新用户手机号
export const UpdatePhone = (id, params) => {
  return put(`/users/staff/admin/${id}/change-phone`, params);
};

// 更新用户的 erp group (徐州G、徐州L、空)
export const updateStaffErpGroup = (id, params) => {
  return put(`/users/staff/admin/${id}/erp-group`, params);
};

// 首页-订单总计
export const HomePage_orderCount = () => {
  return get("/stats/admin/home/order");
};
// 首页-今月销售额
export const HomePage_MonthMoney = (params) => {
  return get("/stats/admin/home/sales", params);
};
// 首页-产品销售排行
export const HomePage_productSale = (time) => {
  return get(`/stats/admin/home/product?time=${time}`);
};
// 首页-店铺加盟趋势
export const HomePage_shop = (time) => {
  return get(`/stats/admin/home/store?time=${time}`);
};
// 首页-销售金额趋势图
export const salesAmountTrendChart = (time) => {
  return get(`/stats/admin/home/sale?time=${time}`);
};
// 首页-各地区销售汇总
export const HomePage_salesArea = () => {
  return get("/stats/admin/home/sales-area");
};
// 首页-月度账单
export const HomePage_bil = () => {
  return get("/stats/admin/home/bill");
};

// 产品列表
export const ProductList = (params) => {
  return get("/products/admin", params);
};

// 产品 - 专供属性设置  G Or L Or N, N mean null
export const updateProductSP = (id, sp) => {
  return put(`/products/admin/${id}/sp`, {
    sp
  });
};

// 产品-属性
// 0: 全部；1：销售品（维娜、SPA）；2：试用装、赠品；3：维娜；4：SPA；5：试用装；
export const ProductAttribute = (range = 0) => {
  return get(`/products/admin/props?range=${range}`);
};

// 获取产品详情
export const productDetails = (id) => {
  return get(`/products/admin/${id}`);
};

// 修改产品
export const updateProductAnalysis = (id, param) => {
  return put(`/products/admin/${id}/analysis`, param);
};

// 绑定产品
export const updateProductUuid = (id, param) => {
  return put(`/products/admin/${id}/uuid`, param);
};

// 获取首页轮播列表
export const bannerList = () => {
  return get("/banner/admin/top");
};

// 新增首页轮播-跳转地址
export const bannerProductAddress = (name, range = 0) => {
  return get("/products/admin/search/name", {
    name,
    range,
  });
};

// 新增首页轮播
export const add_banner = (params) => {
  return post("/banner/admin/top", params);
};

// 首页轮播详情
export const bannerDetail = (id) => {
  return get(`/banner/admin/top/${id}`);
};

// 修改首页轮播
export const editBanner = (id, params) => {
  return put(`/banner/admin/top/${id}`, params);
};

// 删除首页轮播
export const delBanner = (id) => {
  return del(`/banner/admin/top/${id}`);
};

// 公告 - 列表
export const notices_list = () => {
  return get("/system/admin/notices");
};

// 公告 - 增
export const notices_add = (params) => {
  return post("/system/admin/notices", params);
};

// 公告 - 详情
export const notices_detail = (id) => {
  return get(`/system/admin/notices/${id}`);
};

// 公告 - 修改
export const notices_edit = (id, params) => {
  return put(`/system/admin/notices/${id}`, params);
};

// 公告 - 删除
export const notices_delete = (id) => {
  return del(`/system/admin/notices/${id}`);
};

// 公告 - 激活
export const notices_active = (id) => {
  return post(`/system/admin/notices/${id}/active`);
};

// 促销横幅列表
export const PromotionDiagramList = () => {
  return get("/banner/admin/reward");
};

// 促销横幅详情
export const PromotionDiagramDetails = (id) => {
  return get(`/banner/admin/reward/${id}`);
};

// 编辑促销横幅
export const editPromotionDiagram = (id, params) => {
  return put(`/banner/admin/reward/${id}`, params);
};

// 签约公司列表 - 创建客户编码
export const agentCreatErp = (id, params) => {
  return post(`/agent/admin/company/${id}/erp`, params);
};

// 签约公司列表 - LBS
export const agentList = (params) => {
  return get("/agent/admin/company", params);
};

// 签约公司列表 - erp
export const erpCompanyList = (params) => {
  return get("/agent/admin/company/erp", params);
};

// 签约公司列表 - erp - ERP签约公司属性修改
export const updateErpCompanyUsed = (id, params) => {
  return put(`/agent/admin/company/erp/${id}/used`, params);
};

// 签约公司列表 - 分配组、团队（team）
export const updateAgentTeam = (id, params) => {
  return put(`/agent/admin/company/${id}/team`, params);
};

// 签约公司列表 - 分配地区
export const updateAgentArea = (id, params) => {
  return put(`/agent/admin/company/${id}/area`, params);
};

// 签约公司列表 - 更新手机号
export const updateCompanyPhone = (id, phone) => {
  return put(`/agent/admin/company/${id}/phone`, {
    phone
  });
};

// 签约公司列表 - 更法人
export const updateCompanyName = (id, name) => {
  return put(`/agent/admin/company/${id}/name`, {
    name
  });
};

// 市场人员 - 更新手机号
export const updateAgentPhone = (id, phone) => {
  return put(`/agent/admin/marketer/${id}/phone`, {
    phone
  });
};

// 市场人员 - 更新上级 erp id
export const updateAgentParent = (id, erpId) => {
  return put(`/agent/admin/marketer/${id}/parent`, {
    erpId
  });
};

// 市场人员 - 更新登录权限
export const updateAgentLogin = (id) => {
  return put(`/agent/admin/marketer/${id}/login`);
};

// 市场人员 - 更新订单权限
export const updateAgentOrder = (id) => {
  return put(`/agent/admin/company/${id}/order`);
};

// 市场人员 - 更新账号权限
export const updateAgentDisable = (id, param) => {
  return put(`/agent/admin/marketer/${id}/disable`, param);
};

// 签约公司 - 更新账号权限
export const updateAgentCompanyDisable = (id, param) => {
  return put(`/agent/admin/company/${id}/disable`, param);
};

// 市场人员 - 小额订单提交权限
export const smallOrder = (id) => {
  return post(`/store/admin/${id}/small-order`);
};

// 客户列表 - 市场人员
export const agentMarketerList = (params) => {
  return get("/agent/admin/marketer", params);
};

// 市场人员 - 创建
export const postAgentMarketer = (params) => {
  return post("/agent/admin/marketer", params);
};

// 签约公司 - 创建
export const postAgentCompany = (params) => {
  return post("/agent/admin/company", params);
};

/**
 * 市场人员 - 分配分组（erp升级）
 * @param id   必 室长id
 * - 以下参数在创建时需要，只传id是进行分配（erp组升级）
 * @param address       非必 地址
 * @param companyCode   非必 开票人code 
 * @param companyName   非必 开票公司名称 
 * @param teamGroup     非必 分组编码（徐州G / 徐州L）
 * */ 
export const agentMarketerGroup = (id, param) => {
  return post(`/agent/admin/marketer/${id}/group`, param);
};

/**
 * 市场人员 - 取消分组（erp降级）
 * @param id   必 室长id
 * */ 
export const deleteAgentMarketerGroup = (id) => {
  return del(`/agent/admin/marketer/${id}/group`);
};

// agent - 创建 leader
export const createAgentLeader = (params) => {
  return post(`/agent/admin/leader`, params);
};

// 市场总监 - 查询 leader
export const getAgentLeader = (params) => {
  return get(`/agent/admin/leader`, params);
};

// 市场总监 - 更新上级公司
export const updateAgentLeaderParent = (id, erpId) => {
  return put(`/agent/admin/leader/${id}/parent`, {
    erpId
  });
};

// 市场总监 - 更新账号状态
export const updateAgentLeaderStatus = (id, params) => {
  return put(`/agent/admin/leader/${id}/status`, params);
};

// 组织架构
export const agentOrganizationChart = (erpId) => {
  return get(`/agent/admin/search/org`, {erpId});
};

// 转移组织查询-不同level查询； 0：签约公司；1：本部长；2：部长；3：室长；
export const agentOrganizationLevel = (level, keyword, type) => {
  return get(`/agent/admin/org/search/movoTo`, {
    level,
    keyword,
    type
  });
}

/***
 * 更新组织架构
 *  level: 0:本部长迁移公司，1:部长迁移本部长,2室长迁移部长，3:店铺迁移室长"
 *  targetParentErpId: "对应迁移上级ERP编码"
 *  targetErpIds: "迁移对象ERP编码"
 * */ 
export const postAgentOrganization = (level, targetParentErpId, targetErpIds) => {
  return post(`/agent/admin/org/search/movoTo`, {
    level,
    targetParentErpId,
    targetErpIds
  });
}

// 客户删除
export const delAgent = (id) => {
  return del(`/users/agent/admin/${id}`);
};

// 客户解绑
export const postAgentUnbind = (id) => {
  return post(`/agent/admin/${id}/unbind`);
};

// 店铺列表
export const FranchiseeList = (params) => {
  return get("/store/admin", params);
};

// 店铺详情
export const FranchiseeDetails = (id) => {
  return get(`/store/admin/${id}`);
};

// SPA审核详情
export const FranchiseeSpaReview = (id) => {
  return get(`/store/admin/spa/${id}/review`);
};

// SPA审核 - 审核
export const FranchiseeReviewSubmit = (id, param) => {
  return post(`/store/admin/spa/${id}/review`, param);
};

// SPA审核表下载
export const downloadQualificationExcel = (id) => {
  return get(`/store/admin/spa/${id}/review/export`, {}, false, 'blob');
};

// 根据erp编码，查询信息
export const storeErpInfo = (id, params) => {
  return get(`/store/admin/${id}/erp`, params);
};

// 创建店铺 erp Id
export const storeErpCreate = (id, params) => {
  return post(`/store/admin/${id}/erp`, params);
};

/**
 * 替换店铺 erp Id  
 *
 * type: A: 秘书替换已脱保店铺编码；B:转让店铺; C: 搬迁；D: 店铺升级；E: 搬迁升级
 * */ 
export const storeErpReplace = (id, erpId, type, body) => {
  return put(`/store/admin/${id}/erp?erpId=${erpId}&type=${type}`, body);
};

// 5万礼包-搜索收货人   type: name or code
export const storeSearchGiftName = (keyword, type) => {
  return get(`/store/admin/search/spa?keyword=${keyword}&type=${type}`);
};

/**
 * 促销赠送-搜索客户
 * @param type 搜索类型： name(名称)、 code(erp编码)
 * @param group 客户分组：01、02、03、04
 * @param ot 订单类型：promotion(促销)、 approval(起案)、normal(普通订单)
 * */ 
export const storeSearchCustomerName = (keyword, type, group, ot) => {
  return get(`/store/admin/search/order?keyword=${keyword}&type=${type}&group=${group}&ot=${ot}`);
};

// 5万礼包-确认收货人
export const storeGiftInfo = (id) => {
  return get(`/store/admin/${id}/gift`);
};

/**
 * 促销赠送-获取客户分组（徐州G、徐州L）
 * @param id 客户id
 * @param group 客户分组：01、02、03、04
 * */ 
export const getCustomerGroup = (id, group) => {
  return get(`/store/admin/${id}/order?group=${group}`);
};


// 开票人-财务-搜索
export const storeCompanySearch = (id, keyword) => {
  return get(`/store/admin/${id}/company?name=${keyword}`);
};

// 店铺 - 更新店铺属性
export const updateStoreProperty = (id, param) => {
  return put(`/store/admin/${id}/property`, param);
};

/**
 * 店铺 - 更新营业状态
 * @param status 营业状态 必 '': 在保，'A'：脱保
 * @param memo   备注 必 限制50字符
 * */ 
export const updateStoreBusinessStatus = (id, param) => {
  return put(`/store/admin/${id}/business-status`, param);
};

/**
 * 店铺 - 延保
 * @param property 店铺属性 必 '': 普通店，'A'：专卖店，'B'：SPA店
 * @param status 营业状态 必 '': 在保，'A'：脱保
 * @param memo   备注 必 限制50字符
 * */ 
export const delayProtect = (id, param) => {
  return put(`/store/admin/${id}/protect`, param);
};

/**
 * 店铺审核
 * @param param.status 审核状态
 * @param param.memo 失败原因
 * @param id   店铺id
*/
export const storeAuditing = (id, param) => {
  return put(`/store/admin/${id}/auditing`, param)
}

/**
 * 店铺管理员日志列表
 * @param id   店铺id
*/
export const getStoreLog = (id) => {
  return get(`/store/admin/${id}/log`)
}

/**
 * 添加店铺管理员日志
 * @param id   店铺id
 * @param param.memo 备注
 * @param param.appendix 附件
*/
export const postStoreLog = (id, param) => {
  return post(`/store/admin/${id}/log`, param)
}

/**
 * 删除店铺管理员日志
 * @param storeId 店铺id
 * @param logId   日志id
*/
export const deleteStoreLog = (storeId, logId) => {
  return put(`/store/admin/${storeId}/log/${logId}`)
}

// 店铺 - 更新市场人员
export const updateStoreSalesman = (id, param) => {
  return put(`/store/admin/${id}/salesman`, param);
};

// 店铺 - 更新店铺图片
export const updateStoreImage = (id, image) => {
  return put(`/store/admin/${id}/image`, {image});
};

// 店铺 - 店铺回收站
export const putStoreDisable = (id) => {
  return put(`/store/admin/${id}/disable`);
};

// 店铺 - 店铺恢复
export const putStoreRestore = (id) => {
  return put(`/store/admin/${id}/restore`);
};

// 店铺 - 删除店铺
export const deleteStore = (id) => {
  return del(`/store/admin/${id}`);
};

// 店铺 - 基础信息修改
export const putStoreInfo = (id, param) => {
  return put(`/store/admin/${id}/info`, param);
};

// 查询省市区
export const province = () => {
  return get("/regions");
};

// 员工列表
export const staffList = (params) => {
  return get("/users/staff/admin", params);
};

// 角色管理列表
export const roleList = () => {
  return get("/roles/admin");
};

// 角色详情
export const roleDetails = (user_id) => {
  return get(`/users/staff/admin/${user_id}`);
};

// 新增员工
export const addStaff = (params) => {
  return post("/users/staff/admin", params);
};

// 更新员工
export const upStaff = (id, params) => {
  return put(`/users/staff/admin/${id}`, params);
};

// 更新店铺地址
export const upJmsAddress = (id, params) => {
  return put(`/franchises/admin/${id}/address`, params);
};

// 删除员工
export const delStaff = (id) => {
  return del(`/users/staff/admin/${id}`);
};

// 重置员工密码
export const resetStaffPassword = (id) => {
  return post(`/users/staff/admin/${id}/reset-passwd`);
};

// 获取员工分配公司列表
export const staffCompanyList = (id, params) => {
  return get(`/users/staff/admin/${id}/audit`, params);
};

// 保存员工/公司分配
export const staffSaveFp = (id, params) => {
  return put(`/users/staff/admin/${id}/audit`, params);
};

// 角色META查询
export const starffMetaList = () => {
  return get("/roles/admin/meta");
};

// 角色-添加
export const addRoles = (params) => {
  return post("/roles/admin", params);
};

// 角色-查询
export const rolesSearch = (id) => {
  return get(`/roles/admin/${id}`);
};

// 角色-编辑保存
export const rolesDetialSave = (id, params) => {
  return put(`/roles/admin/${id}`, params);
};

// 角色-删除
export const delRoles = (id) => {
  return del(`/roles/admin/${id}`);
};

// 协议管理-列表
export const pagesList = () => {
  return get("/pages/admin");
};
// 协议管理-添加
export const addPagesAdmin = (params) => {
  return post("/pages/admin", params);
};
// 协议管理-查询
export const pagesAdminSearch = (id) => {
  return get(`/pages/admin/${id}`);
};
// 协议管理-更新
export const pagesAdminUp = (id, params) => {
  return put(`/pages/admin/${id}`, params);
};
// 协议管理-删除
export const pagesAdminDel = (id) => {
  return del(`/pages/admin/${id}`);
};

// 产品促销-列表
export const productList = (params) => {
  return get("/promotion/admin", params);
};

// 产品促销-系列-分组
export const productPromotionGroupSeries = (range) => {
  return get(`/products/admin/props?range=${range}`);
};

// 产品促销-选择产品
export const productPromotionList = (params, id = 0) => {
  return get(`/promotion/admin/${id}/mapping`, params);
};

// 产品促销-名称查询
export const productNameQuery = (params) => {
  return get(`/products/admin/search/name`, params);
};

// 产品促销-新建保存
export const newProductSave = (params) => {
  return post("/promotion/admin", params);
};

// 产品促销-详情
export const productPromotionDetails = (url) => {
  return get(`/promotion/admin/${url}`);
};

// 产品促销-更新
export const productPromotionUpdate = (url, params) => {
  return put(`/promotion/admin/${url}`, params);
};

// 产品促销-删除
export const delProductPromotion = (url) => {
  return del(`/promotion/admin/${url}`);
};

// 产品促销-提交
export const sumbitProductPromoiton = (url) => {
  return post(`/promotion/admin/${url}/commit`);
};

// 产品促销-待审核-通过/驳回
export const examineProduct = (url, pass) => {
  return post(`/promotion/admin/${url}/exam`, {
    pass,
  });
};

// 产品促销-待发布-发布/驳回
export const releaseProdct = (url, pass, date = null) => {
  return post(`/promotion/admin/${url}/release`, {
    pass,
    date,
  });
};
// 产品促销-待激活-撤回
export const whithdrawProduct = (url) => {
  return post(`/promotion/admin/${url}/withdraw`);
};

// weina-列表
export const weinaSaleList = (params) => {
  return get("/reward/admin/weina", params);
};

// 维娜-创建
export const weinaAdd = (params) => {
  return post(`/reward/admin/weina`, params);
};

// 维娜-更新
export const weinaUpdate = (url, params) => {
  return put(`/reward/admin/weina/${url}`, params);
};

// 维娜-详情
export const weinaDetails = (url) => {
  return get(`/reward/admin/weina/${url}`);
};

// 维娜-查询属性-试用装
export const weinaTry = (range) => {
  return get(`/products/admin/props?range=${range}`);
};

// 维娜-模糊查询-试用装
export const weinaTryList = (url, params, type) => {
  return get(`/reward/admin/weina/${url}/mapping`, {
    ...params,
    type,
  });
};

// 维娜-删除
export const weinaDel = (url) => {
  return del(`/reward/admin/weina/${url}`);
};

// 维娜-提交
export const weinaSumbit = (url) => {
  return post(`/reward/admin/weina/${url}/commit`);
};

// 维娜-审核
export const weinaCareful = (url, params) => {
  return post(`/reward/admin/weina/${url}/exam`, params);
};

// 维娜-发布
export const weinaPublish = (url, params) => {
  return post(`/reward/admin/weina/${url}/release`, params);
};

// 维娜-撤回
export const weinaRecall = (url, params) => {
  return post(`/reward/admin/weina/${url}/withdraw`, params);
};

// spa-列表
export const sapList = (params) => {
  return get("/reward/admin/spa", params);
};

// spa-新增
export const spaAdd = (params) => {
  return post("/reward/admin/spa", params);
};

// spa-详情
export const spaDetails = (url) => {
  return get(`/reward/admin/spa/${url}`);
};

// spa-属性
export const spaProps = () => {
  return get("/products/admin/props?range=4");
};

// spa-匹配产品
export const spaProduct = (url, params) => {
  return get(`/reward/admin/spa/${url}/mapping`, params);
};

// spa-更新
export const spaUpdate = (url, params) => {
  return put(`/reward/admin/spa/${url}`, params);
};

// spa-删除
export const spaDel = (url) => {
  return del(`/reward/admin/spa/${url}`);
};

// spa-提交
export const spaSumbit = (url) => {
  return post(`/reward/admin/spa/${url}/commit`);
};

// spa-待审核-审核/驳回
export const spaExam = (url, params) => {
  return post(`/reward/admin/spa/${url}/exam`, params);
};

// spa-发布/驳回
export const spaRelease = (url, params) => {
  return post(`/reward/admin/spa/${url}/release`, params);
};

// spa-撤回
export const spaWithdraw = (url, params) => {
  return post(`/reward/admin/spa/${url}/withdraw`, params);
};

// 5w-自购产品产品  id 传 0
export const fiveWProductList = (id, params) => {
  return get(`/reward/admin/gift/${id}/mapping`, params);
};

// 促销赠送-产品搜索  id 传 0
export const promotionProductList = (id, params) => {
  return get(`/reward/admin/promotion/${id}/mapping`, params);
};

// 起案赠送-产品搜索  id 传 0
export const approvalProductList = (id, params) => {
  return get(`/reward/admin/approval/${id}/mapping`, params);
};

// 5w-列表
export const fiveWList = (params) => {
  return get("/gift/admin", params);
};

// 5w-新增
export const fiveWAdd = (params) => {
  return post("/gift/admin", params);
};

// 5w-详情
export const fiveWDetails = (url) => {
  return get(`/gift/admin/${url}`);
};

// 5w-查询属性
export const fiveWAttribute = () => {
  return get("/products/admin/props?range=6");
};

// 5w-匹配产品
export const fiveWProduct = (url, params) => {
  return get(`/gift/admin/${url}/mapping`, params);
};

// 5w-更新产品
export const fiveWUpdate = (url, params) => {
  return put(`/gift/admin/${url}`, params);
};

// 5w-删除
export const fiveWDel = (url) => {
  return del(`/gift/admin/${url}`);
};

// 5w-提交
export const fiveWSumbit = (url, params) => {
  return post(`/gift/admin/${url}/commit`, params);
};

// 5w-审核/驳回
export const fiveWexam = (url, params) => {
  return post(`/gift/admin/${url}/exam`, params);
};

// 5w-发布/驳回
export const fiveWrelease = (url, params) => {
  return post(`/gift/admin/${url}/release`, params);
};

// 5w-撤回
export const fiveWwithdraw = (url, params) => {
  return post(`/gift/admin/${url}/withdraw`, params);
};

// 销售统计-销售金额趋势
export const sellCensus = (time) => {
  return get(`/stats/admin/stat/sales-trend?time=${time}`);
};

// 销售统计-按产品
export const salesProduct = (month) => {
  return get(`/stats/admin/stat/sales-product?month=${month}`);
};

// 销售统计-按促销
export const salesPromotion = (month) => {
  return get(`/stats/admin/stat/sales-promotion?month=${month}`);
};

// 销售统计-按地区
export const salesRegion = (month) => {
  return get(`/stats/admin/stat/sales-region?month=${month}`);
};

// 销售业绩
export const achievement = (params) => {
  return get("/stats/admin/stat/achievement", params);
};

// 加盟统计
export const joinCount = (params) => {
  return get("/stats/admin/stat/join", params);
};

// 购买率
export const repurchase = (params) => {
  return get("/stats/admin/stat/repurchase", params);
};

// 产品库存
export const stock = (params) => {
  return get("/stats/admin/stat/stock", params);
};

// 专家建议 - 列表
export const advice_list = () => {
  return get("/spa/admin/advices");
};

// 专家建议 - 详情
export const advice_detail = (id) => {
  return get(`/spa/admin/advices/${id}`);
};

// 专家建议 - 修改
export const advice_edit = (id, params) => {
  return put(`/spa/admin/advices/${id}`, params);
};

// 订单查询
export const orderList = (params) => {
  return get("/tx/admin/", params);
};
// 订单详情
export const orderDetails = (id) => {
  return get(`/tx/admin/${id}`);
};
// 订单 - 产品替换 - 查询
export const getOrderProductReplace = (params) => {
  return get(`/tx/admin/replace`, params);
};
// 订单 - 产品替换 - 替换
export const postOrderProductReplace = (params) => {
  return post(`/tx/admin/replace`, params);
};
// 模糊搜索店铺/收货人
export const orderSearchName = (name) => {
  return get(`/store/admin/search?name=${name}`);
};

/**
 * 判断当前店铺/收货人能否变更
 * @param {店铺id/收货人ID} storeId
 * @param {订单ID} id
 * @returns
 */
export const changeReceiver = (storeId, id) => {
  return post(`/tx/admin/${id}/change-receiver`, { storeId });
};

/**
 * 传入数值变更收货人
 * @param {订单id} id
 * @param {要修改店铺/收货人的参数} params
 * @returns
 */
export const confirmChangeReceiver = (id, params) => {
  return put(`/tx/admin/${id}/change-receiver`, params);
};

/**
 * 秘书审核-通过/驳回
 * @param {订单} id
 * @param {参数} params
 * @returns
 */
export const secretarySumbit = (id, params) => {
  return post(`/tx/admin/${id}/submit`, params);
};

/**
 * 秘书撤回
 * @param {订单} id
 * @returns
 */
export const submitWithdraw = (id) => {
  return post(`/tx/admin/${id}/submit/withdraw`);
};

/**
 * 总部预审撤回
 * @param {订单} id
 * @returns
 */
export const reviewWithdraw = (id) => {
  return post(`/tx/admin/${id}/review/withdraw`);
};

/**
 * 总部预审审核-通过/驳回
 * @param {订单} id
 * @param {参数} params
 * @returns
 */
export const reviewSumbit = (id, params) => {
  return post(`/tx/admin/${id}/review`, params);
};

/**
 * 财务审核-通过/驳回
 * @param {订单} id
 * @param {参数} params
 * @returns
 */
export const financeSumbit = (id, params) => {
  return post(`/tx/admin/${id}/examine`, params);
};

/**
 * 财务审核-批量-通过/驳回
 * @param {参数} params
 * @returns
 */
export const financeBatchSumbit = (params) => {
  return post(`/tx/admin/bulk/examine`, params);
};

/**
 * 总部审核-通过/驳回
 * @param {订单} id
 * @param {参数} params
 * @returns
 */
export const hexamineSumbit = (id, params) => {
  return post(`/tx/admin/${id}/hexamine`, params);
};

/**
 * 审核申请-通过/驳回
 * @param {订单} id
 * @param {参数} params
 * @returns
 */
export const receiverSumbit = (id, params) => {
  return post(`/tx/admin/${id}/change-receiver/commit`, params);
};

/**
 * 订单 - 关闭 / 打开
 * @param {订单} id
 * @param {参数} type
 * @returns
 */
export const changeOrderStatus = (id, type) => {
  return post(`/tx/admin/${id}/change-order/status?type=${type}`);
};

/**
 * 订单 - 退回订单
 * @param {订单} id
 * @param {参数} param
 * @returns
 */
export const rollbackOrder = (id, param) => {
  return post(`/tx/admin/${id}/rollback`, param);
};

/**
 * 订单占用延迟
 * @param {订单} id
 * @returns
 * 
 * 占用   409
 * 没权限 406
 */
export const postRights = (id) => {
  return post(`/tx/admin/${id}/rights`, {}, true);
};
/**
 * 订单释放延迟
 * @param {订单} id
 * @returns
 */
export const delRights = (id) => {
  return del(`/tx/admin/${id}/rights`, {}, true);
};

/**
 * 超找变更产品
 * @param {订单id} id
 * @returns
 */
export const replaceable = (id) => {
  return get(`/tx/admin/${id}/replaceable`);
};

// 按价格搜索产品
export const samePrice = (id, price) => {
  return get(`/tx/admin/${id}/search/same-price?price=${price}`);
};
// 变更产品
export const replaceableProduct = (id, params) => {
  return put(`/tx/admin/${id}/replaceable`, params);
};
// 变更产品 - 获取产品列表
export const getReplacePromotion = (id) => {
  return get(`/tx/admin/${id}/replace/promotion`);
};
// 变更产品 - 修改
export const putReplacePromotion = (id, params) => {
  return put(`/tx/admin/${id}/replace/promotion`, params);
};
// 删除订单
export const delOrder = (id) => {
  return del(`/tx/admin/${id}`);
};

// 保存五万礼包
export const saveGiftOrder = (id, param) => {
  return post(`/tx/admin/${id}/commit/gift`, param);
};

// 保存促销赠送
export const savePromotionOrder = (id, param) => {
  return post(`/tx/admin/${id}/commit/promotion`, param);
};

// 保存起案赠送
export const saveApprovalOrder = (id, param) => {
  return post(`/tx/admin/${id}/commit/approval`, param);
};

// 保存普通销售订单
export const saveFreeFormOrder = (id, param) => {
  return post(`/tx/admin/${id}/commit/freeForm`, param);
};

// 导出异常订单前 获取 token
export const exportAbnormalToken = () => {
  return post('tx/admin/export/abnormal')
}

// 导出订单前 获取 token
export const exportFinancialToken = () => {
  return post('tx/admin/export/financial')
}

// 导出店铺前 获取 token
export const exportStoreToken = () => {
  return post('store/admin/export')
}

// 导出数据分析前 获取 token
export const exportDataToken = () => {
  return post('stats/admin/data/export')
}

// 查询部分发货订单关闭
export const postStopOrderClose = (param) => {
  return post('tx/admin/close', param, false, null, { "Content-Type": "multipart/form-data" })
}

// 提交部分发货订单关闭
export const putStopOrderClose = (param) => {
  return put('tx/admin/close', param)
}

// 店铺批量脱保
export const postBatchStoreUnprotect = (param) => {
  return post('store/admin/bulk/unprotect', param, false, null, { "Content-Type": "multipart/form-data" })
}

// 店铺批量脱保 - 列表
export const getBatchStoreUnprotect = (param) => {
  return get('store/admin/bulk/unprotect', param)
}

// 店铺批量审核失败 - 上传文件
export const postBatchStoreFailAudit = (param) => {
  return post('store/admin/bulk/fail-audit', param, false, null, { "Content-Type": "multipart/form-data" })
}

// 店铺批量审核失败 - 确认
export const postBatchStoreFailAuditCommit = (param) => {
  return post('store/admin/bulk/fail-audit/commit', param)
}

// 地图-店铺
export const storeMap = (param) => {
  return get("/store/admin/map", param);
}


/**
 * fc时间触发 - 数据同步
 * payload 说明：
 *  product_sync: 产品同步
 *  company_sync: 公司、销售组同步
 *  syncReverseOrder：反审核订单同步
 *  syncPromotionRelease: 促销同步（产品、维娜加盟、SPA加盟）
 *  syncReopenOrder 同步关闭订单
 * */ 
export const fcDataSync = (payload, param) => {
  let par = {
    ...param,
    payload
  }
  return post("/data/admin/sync/fc", par);
};

// 异常订单下载
export const addressAnomalyExcel = () => {
  return get(fcBaseURL + "/addressAnomalyExcel", {}, false, 'blob');
}

// 财务订单下载
export const financeOrderExport = (param) => {
  return post(fcBaseURL + "/financeOrderExport", param, false, 'blob');
}

// 财务订单明细下载
export const financeOrderDetailExport = (param) => {
  return post(fcBaseURL + "/financeOrderDetailExport", param, false, 'blob');
}

// 店铺列表下载
export const storeListExport = (param) => {
  return post(fcBaseURL + "/storeExport", param, false, 'blob');
}

// 数据分析 - 导出数据
// 销售排名 - 本部长
export const salerRankingDirector = (param) => {
  return post(fcBaseURL + "/salerRankingDirector", param, false, 'blob');
}
// 销售排名 - 部长
export const salerRankingLeader = (param) => {
  return post(fcBaseURL + "/salerRankingLeader", param, false, 'blob');
}
// 销售排名 - 室长
export const salerRankingMarket = (param) => {
  return post(fcBaseURL + "/salerRankingMarket", param, false, 'blob');
}

// 年销售统计 - 本部长
export const yearSaleDirector = (param) => {
  return post(fcBaseURL + "/yearSaleDirector", param, false, 'blob');
}
// 年销售统计 - 部长
export const yearSaleLeader = (param) => {
  return post(fcBaseURL + "/yearSaleLeader", param, false, 'blob');
}
// 年销售统计 - 室长
export const yearSaleMarket = (param) => {
  return post(fcBaseURL + "/yearSaleMarket", param, false, 'blob');
}

// 月销售统计 - 本部长
export const monthSaleDirector = (param) => {
  return post(fcBaseURL + "/monthSaleDirector", param, false, 'blob');
}
// 月销售统计 - 部长
export const monthSaleLeader = (param) => {
  return post(fcBaseURL + "/monthSaleLeader", param, false, 'blob');
}
// 月销售统计 - 室长
export const monthSaleMarket = (param) => {
  return post(fcBaseURL + "/monthSaleMarket", param, false, 'blob');
}

// 组织架构
export const organizationExport = (param) => {
  return post(fcBaseURL + "/organizationExport", param, false, 'blob');
}
// 店铺列表
export const salesStoreListExport = (param) => {
  return post(fcBaseURL + "/storeListExport", param, false, 'blob');
}

// 新注册 - 室长
export const exportNewRegisterMarketList = (param) => {
  return post(fcBaseURL + "/newRegisterMarketList", param, false, 'blob');
}
// 新注册 - 店铺
export const exportNewRegisterStoreList = (param) => {
  return post(fcBaseURL + "/newRegisterStoreList", param, false, 'blob');
}

// 店铺 - 销售额排名
export const exportRankingByStore = (param) => {
  return post(fcBaseURL + "/rankingByStore", param, false, 'blob');
}
// 店铺 - 店铺列表
export const exportYearListByStore = (param) => {
  return post(fcBaseURL + "/yearListByStore", param, false, 'blob');
}
// 店铺 - 销售额追踪
export const exportContinuousStoreList = (param) => {
  return post(fcBaseURL + "/continuousStoreList", param, false, 'blob');
}

// 室长 - 销售额排名
export const exportRankingByMarket = (param) => {
  return post(fcBaseURL + "/rankingByMarket", param, false, 'blob');
}
// 室长 - 室长列表
export const exportYearListByMarket = (param) => {
  return post(fcBaseURL + "/yearListByMarket", param, false, 'blob');
}
// 室长 - 销售额追踪
export const exportContinuousMarketList = (param) => {
  return post(fcBaseURL + "/continuousMarketList", param, false, 'blob');
}
// 产品销量
export const exportProductSalesList = (param) => {
  return post(fcBaseURL + "/productSalesList", param, false, 'blob');
}

/**
 * 店铺列表
 * @param {省代理 - 组织id} id
 * @param {开始时间} start
 * @param {结束时间} end
 * @param {地区 省 id} province
 * @param {地区 市 id} city
 * @returns
 */
export const storeList = (id, start, end, province, city) => {
  return get(`http://localhost:8000/order/storeList?id=${id}&start=${start}&end=${end}&province=${province}&city=${city}`);
};


/**
 * 代理列表
 * @returns
 */
export const userDList = () => {
  return get(`http://localhost:8000/user/dList`);
};

/**
 * 店铺行政区域
 * @returns
 */
export const storeRegion = () => {
  return get(`http://localhost:8000/store/region`);
};




/*********************************************** 销售分析 **********************************************/
// 1.1 历年销售情况汇总
export const salesSummary = (param) => {
  return get('stats/admin/analysis/sales/year', param)
}
// 1.2 分地区、分年度销售情况汇总
export const salesSummaryByArea = (param) => {
  return get('stats/admin/analysis/sales/areaAndYear', param)
}

// 1.3 各部门经理/部门经理/总监职位的年度销售汇总
// 1.3.1 本部长级销售统计
export const salesByDirector = (param) => {
  return get('stats/admin/analysis/sales/director', param)
}
// 1.3.2 部长级销售统计
export const salesByLeader = (param) => {
  return get('stats/admin/analysis/sales/leader', param)
}
// 1.3.3 室长级销售统计
export const salesByMarket = (param) => {
  return get('stats/admin/analysis/sales/marketer', param)
}

// 1.4 销售排名
// 1.4.1 室长销售排名
export const rankingByMarket = (param) => {
  return get('stats/admin/analysis/ranking/marketer', param)
}
// 1.4.2 部长销售排名
export const rankingByLeader = (param) => {
  return get('stats/admin/analysis/ranking/leader', param)
}
// 1.4.3 本部长销售排名
export const rankingByDirector = (param) => {
  return get('stats/admin/analysis/ranking/director', param)
}

//1.5 按职位每月详细销售统计
// 1.5.1 本部长每月明细销售统计
export const monthByDirector = (param) => {
  return get('stats/admin/analysis/monthly/director', param)
}
// 1.5.2 部长每月明细销售统计
export const monthByLeader = (param) => {
  return get('stats/admin/analysis/monthly/leader', param)
}
// 1.5.3 室长每月明细销售统计
export const monthByMarket = (param) => {
  return get('stats/admin/analysis/monthly/marketer', param)
}

// 2.1 组织架构
export const organization = (param) => {
  return get('stats/admin/analysis/organization', param)
}

// 3. 商店和商店搜索
// 3.1.所有店铺信息（均为2020年起）
export const salesStoreList = (param) => {
  return get('stats/admin/analysis/stores', param)
}
// 3.2.今年新注册门店信息（仅限去年无交易记录的店铺）
export const newRegisterStoreList = (param) => {
  return get('stats/admin/analysis/stores/new', param)
}
// 3.3.今年新登记的室长信息（仅限去年无交易记录的经理）
export const newRegisterMarketList = (param) => {
  return get('stats/admin/analysis/marketers/new', param)
}

// 4、优秀店及专卖店
// 4.1、2020年至2024年连续交易4年（3年、2年）的店铺（每年都有交易记录）
export const continuousStoreList = (param) => {
  return get('stats/admin/analysis/stores/good', param)
}
// 4.2 特定店铺每年的交易笔数和交易金额
export const storeByYearList = (param) => {
  return get('stats/admin/analysis/stores/summary', param)
}
// 4.6 店铺销售额排行
export const storeSalesList = (param) => {
  return get('stats/admin/analysis/agents/ranking/store', param)
}
// 4.3 2020年至2024年连续交易4年（3年、2年）的室长（每年都有交易记录）
export const continuousMarketList = (param) => {
  return get('stats/admin/analysis/agents/continous', param)
}
// 4.4 室长每年的交易次数和交易金额
export const marketByYearList = (param) => {
  return get('stats/admin/analysis/agents/continous/marketer', param)
}
// 4.5 室长销售额排行
export const marketSalesList = (param) => {
  return get('stats/admin/analysis/agents/ranking/marketer', param)
}

// 5、历年畅销产品排名
export const productSalesList = (param) => {
  return get('stats/admin/analysis/products/ranking', param)
}

// 店铺业绩 - 店铺详情
export const analysisStoresSummaryDetail = (id) => {
  return get(`/stats/admin/analysis/stores/summary/${id}`);
};

// 店铺业绩 - 室长详情
export const analysisContinousMarketerDetail = (erpId) => {
  return get(`/stats/admin/analysis/agents/continous/marketer/${erpId}`);
};